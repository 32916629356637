import "./style.css";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/* essentials */

function hasClass(el, className) {
  if (el.classList) return el.classList.contains(className);
  else
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
}

function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += " " + className;
}

function removeClass(el, className) {
  if (el) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className))
      el.className = el.className.replace(
        new RegExp("(\\s|^)" + className + "(\\s|$)"),
        " "
      );
  }
}

if (isMobile) {
  addClass(document.body, "is-mobile");
}

/* contact */

const contactForm = document.getElementById("contact-form");
const contactName = document.getElementById("contact-name");
const contactEmail = document.getElementById("contact-email");
const contactMessage = document.getElementById("contact-message");

function onError(request) {
  alert("Something went wrong. :( Please contact me directly via email.");

  console.error("ERROR");
  console.log(request);
}

contactForm.addEventListener("submit", (event) => {
  event.preventDefault();

  const request = new XMLHttpRequest();
  request.open("POST", "./", true);
  request.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );
  request.onload = () => {
    if (request.status === 200) {
      const thanksMessage = contactForm.getAttribute("data-thanks-message");

      contactForm.innerHTML = `<h2 class="appear mt-3 mb-2 text-favorite">${thanksMessage}</h2>`;
    } else {
      onError(request);
    }
  };
  request.onerror = () => {
    onError(request);
  };

  request.send(
    `name=${encodeURIComponent(contactName.value)}&email=${encodeURIComponent(
      contactEmail.value
    )}&message=${encodeURIComponent(contactMessage.value)}`
  );
});

/* mockups */

// Add buttons (to ensure they are only shown when JS is enabled)
let playButton =
  '<button class="mockup-btn play" aria-label="Play Video">\n' +
  '    <span class="mockup-icon icon-pause"></span>\n' +
  "</button>";
let mockupElements = document.querySelectorAll(".mockup");
mockupElements.forEach((mockupEl) => {
  mockupEl.insertAdjacentHTML("beforeend", playButton);
});

// Get new mockup elements (with added buttons)
mockupElements = document.querySelectorAll(".mockup");

let mockups = [];
mockupElements.forEach((mockupEl) => {
  let mockup = {
    video: mockupEl.querySelector(".mockup-video"),
    btn: mockupEl.querySelector(".mockup-btn"),
    icon: mockupEl.querySelector(".mockup-icon"),
    manuallyPaused: false,
  };

  mockup.play = () => {
    // Play the video
    mockup.video.play();

    addClass(mockup.btn, "play");
    addClass(mockup.icon, "icon-pause");
    removeClass(mockup.icon, "icon-play2");
  };

  mockup.pause = () => {
    // Pause the video
    mockup.video.pause();

    mockup.manuallyPaused = true;

    removeClass(mockup.btn, "play");
    addClass(mockup.icon, "icon-play2");
    removeClass(mockup.icon, "icon-pause");
  };

  mockup.btn.onclick = () => {
    if (mockup.video.paused) {
      mockup.play();
    } else {
      mockup.pause();
    }
  };

  mockups.push(mockup);
});

/* smooth scroll */

const nav = document.querySelector(".nav");
const navMenuToggler = document.querySelector(".nav-menu-toggler");

function setCurrentSection(a) {
  const target = a.getAttribute("href");
  const targetElement = document.getElementById(target);

  document.getElementById("current-section-title").innerText = a.innerText;
  document.body.setAttribute("data-section", target);

  if (targetElement) {
    document.querySelector("input").focus();
  }

  if (target === "#references") {
    mockups.forEach((mockup) => {
      if (!mockup.manuallyPaused) {
        mockup.play();
      }
    });
  }
}

function smoothScroll() {
  const menuLinks = document.querySelectorAll(".nav-menu-items > a");

  menuLinks.forEach((a) => {
    a.onclick = (event) => {
      const target = a.getAttribute("href");

      document.querySelector(target).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      event.preventDefault();
    };
  });

  const sectionElements = document.querySelectorAll("section");
  let sections = {};
  let j = 0;

  sectionElements.forEach((e) => {
    sections[e.id] = e.offsetTop - 10;
  });

  window.onscroll = () => {
    const scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    for (j in sections) {
      if (sections[j] <= scrollPosition) {
        document.querySelector(".active").setAttribute("class", " ");
        document
          .querySelector("a[href*=" + j + "]")
          .setAttribute("class", "active");
      }
    }

    setCurrentSection(document.querySelector(".active"));
  };
}

smoothScroll();

window.addEventListener("resize", () => {
  smoothScroll();
});

/* mobile menu */

if (isMobile) {
  navMenuToggler.onclick = () => {
    if (hasClass(nav, "full")) {
      removeClass(nav, "full");
    } else {
      addClass(nav, "full");
      document.querySelector(".nav.full .nav-menu-items").onclick = () => {
        removeClass(nav, "full");
      };
    }
  };
}
